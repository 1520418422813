import React from 'react';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import '../../Content/css/aboutProgramme.css'
import PageHeader from '../PageComponents/PageHeader';
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;
export default class AboutTheProgramme extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gravContent: this.props.pageContext.frontmatter,
			headContent: this.props.pageContext.head,
			pageMedia: gravURL + '/uploads/images/',
			innerStyle: {},
		}
	}
	render() {
		let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc,} = this.props.pageContext.head;
		let imgStyle = {
			backgroundImage: 'url(' + this.state.pageMedia + this.state.gravContent.theProgrammeBgImage + ')',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'auto',
		};
		return (
			<main>
				<Helmet>
					<title>About the programme | New Image™ Keto | Keto Program</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:url" content={url?siteURL + url:siteURL} />
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={shareImg} />
					<meta property="og:image:url" content={shareImg}/>
					<meta name="twitter:image" content={shareImg}/>
					<meta name="twitter:card" content={shareImg}/>
				</Helmet>	
					<div className="centerProgramme">
						<div className="aboutProgrammePageWrapper">
							<div className="aboutProgrammeTile" style={imgStyle}>
								<div style={this.state.innerStyle} className="centerProgrammeInner" id="programmeInner">
									<PageHeader mainHeading="Alpha Lipid™ SDII™" subHeading={this.state.gravContent.AboutProgrammeSubHeading}/>
									<section>
										<div className="aboutProgrammeCopyWrapper text-center">
											{ReactHtmlParser(this.state.gravContent.pageContent)}
										</div>
									</section>
								</div>
							</div>			
						</div>
					</div>
			</main>
		);
	}
}
